import axiosInstance from '@/plugins/axios';

export default {
  /**
   * @Function to send data and get prediction for the data sent
   * @Author samuel
   * @param {*} commit
   * @param {string} payload
   * @description function to get prediction for data sent
   * @returns {Promise}
   */
  async PREDICTIVE_ANALYSIS({ commit }, payload) {
    try {
      const { data } = await axiosInstance.post('https://msdat2api.e4eweb.space/api/data/predictive_analysis/', payload);
      commit('setPredictedData', data);
      console.log(data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   * @Function to set section title in the state
   * @Author moses
   * @param {*} commit
   * @param {string} payload
   * @description function to set section title in the state
   * @returns {Promise}
   */
  setSectionTitle({ commit }, payload) {
    commit('setSectionTitle', payload);
  },
};
