<!-- eslint-disable indent -->
<script>
import VoiceMixin from './mixin';
import { VoiceService } from './service';

export default {
  name: 'Voice',
  mixins: [VoiceMixin],
  computed: {
    status() {
      if (this.isRecording) return 'Recording';
      if (this.isAnalyzing) return 'Analyzing';
      return '';
    },
  },
  watch: {
    intent: {
      async handler(newIntent) {
        const voiceService = new VoiceService(this);
        switch (newIntent) {
          case 'About_platform':
            await voiceService.runAbout();
            break;
          case 'Single_indicator':
            await null;
            break;
          case 'Downloadchart':
            await null;
            break;
          // Add more cases as needed
          default:
            break;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<template>
  <div class="wrap">
    <p>
      {{ status }}
    </p>
    <div class="containerr">
      <div @click="start" class="glow" :class="{ recording: isRecording }">
        <img style="width: 200px; height: auto" src="/img/icons/mic.svg" alt="" />
      </div>
    </div>
    <div v-if="intent" class="intent">
      <h1>Intent:</h1>
      <h2>
        {{ intent }}
      </h2>
    </div>
  </div>
</template>

<style scoped>
.wrap {
  padding: 140px;
}

p {
  width: 100%;
  text-align: center;
}

.containerr {
  background-color: rgba(24, 255, 178, 0.4);
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 90px;
  padding: 50px;
}

.glow {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  padding: 2rem;
  background: rgb(175, 187, 255);
  display: flex;
  align-items: center;
  justify-content: center;
}

.img {
  width: 180px;
  height: auto;
}

.recording {
  animation: breathing 2s ease-out infinite normal;
  background: rgb(175, 187, 255);
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.intent {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #dbffc5;
  border-radius: 30px;
  padding: 30px;
}
</style>
