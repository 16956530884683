import { VoiceService } from './service';

export default {
  data() {
    return {
      url: null,
      isRecording: false,
      isAnalyzing: false,
      mediaRecorder: null,
      transcription: null,
      intent: null,
    };
  },
  methods: {
    async makeApiRequest(message) {
      this.isAnalyzing = true;
      try {
        const voiceService = new VoiceService(this);
        const data = await voiceService.getAction(message);
        this.isAnalyzing = false;
        if (data.intents.length === 0) {
          this.isRecording = false;
          this.isAnalyzing = false;
          // eslint-disable-next-line no-alert
          alert("Sorry, we didn't catch that");
        }
        this.intent = data.intents[0].name;
        return;
      } catch (error) {
        this.isAnalyzing = false;
      }
    },
    start() {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();

      recognition.start();

      recognition.onaudiostart = async () => {
        this.isRecording = true;
        this.intent = null;
        this.transcription = null;
        this.isAnalyzing = false;
      };

      recognition.onresult = async (e) => {
        this.transcription = e.results[0][0].transcript;
        console.log(this.transcription);
        if (this.transcription) {
          this.isRecording = false;
          await this.makeApiRequest(this.transcription);
          // eslint-disable-next-line no-alert
        } else alert('we didnt quite catch that');
      };

      recognition.onend = () => {
        this.isRecording = false;
      };
    },
  },
};
