import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export class VoiceService {
   vue;

   constructor(vueInstance) {
     this.vue = vueInstance;
   }

    readonly API_ENDPOINT = 'https://api.wit.ai/message';

    readonly WIT_ACCESS_TOKEN = '2G3Q4O6OV7M755I3OU55CTJMEIBTDP5E';

    async getAction(message : string) {
      const headers = {
        Authorization: `Bearer ${this.WIT_ACCESS_TOKEN}`,
      };
      const params = {
        q: message,
      };

      const queryString = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');

      const url = `${this.API_ENDPOINT}?${queryString}`;
      try {
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }

    // eslint-disable-next-line class-methods-use-this
    runAbout() {
      this.vue.$router.push('/about');
    }
}
