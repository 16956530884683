var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid card shadow dropdown-expanded work-sans"},[_c('div',{staticClass:"row p-3"},[_c('div',{staticClass:"col mb-3"},[_c('b-list-group',[_c('h5',{staticClass:"text-underline"},[_vm._v("Population")]),_c('router-link',{attrs:{"to":"/dashboard/Demographics","target":"_blank"}},[_c('b-list-group-item',[_vm._v("Demographics")])],1),_c('router-link',{attrs:{"to":"/dashboard/GIS_Mapping","target":"_blank"}},[_c('b-list-group-item',[_vm._v("GIS Mapping")])],1)],1)],1),_c('div',{staticClass:"col mb-3"},[_c('b-list-group',[_c('h5',{staticClass:"text-underline"},[_vm._v("Health Input")]),_c('router-link',{attrs:{"to":"/dashboard/Health_Facility","target":"_blank"}},[_c('b-list-group-item',[_vm._v("Health Facility")])],1),_c('router-link',{attrs:{"to":"/dashboard/Health_Financing","target":"_blank"}},[_c('b-list-group-item',[_vm._v("Health Finance")])],1),_c('router-link',{attrs:{"to":"/dashboard/Health_Workforce","target":"_blank"}},[_c('b-list-group-item',[_vm._v("Health Workforce")])],1),_c('router-link',{attrs:{"to":"/external-ndr1","target":"_blank"}},[_c('b-list-group-item',[_vm._v("National Data Repository Dashboard 1")])],1),_c('router-link',{attrs:{"to":"/external-ndr2","target":"_blank"}},[_c('b-list-group-item',[_vm._v("National Data Repository Dashboard 2")])],1),_vm._l((_vm.publicDashboards.filter((dash) => {
              return (dash.category === 'health_input' && dash.isConfirmed)
            })),function(dashboard){return _c('a',{key:dashboard.id,attrs:{"target":"_blank","href":dashboard.link}},[_c('b-list-group-item',[_vm._v(" "+_vm._s(dashboard.name_of_dashboard)+" ")])],1)})],2)],1),_c('div',{staticClass:"col mb-3"},[_c('b-list-group',[_c('h5',{staticClass:"text-underline"},[_vm._v("Health Outputs")]),_c('router-link',{attrs:{"to":"/dashboard/Health_Service_Access","target":"_blank"}},[_c('b-list-group-item',[_vm._v("Health Service Access")])],1),_c('a',{attrs:{"href":"https://msdat.fmohconnect.gov.ng/covid19_health_service_uptake/index.html","target":"_blank"}},[_c('b-list-group-item',[_vm._v("HSU Dashboard")])],1),_c('a',{attrs:{"href":"https://monthly-nhmis-analysis.fmohconnect.gov.ng/","target":"_blank"}},[_c('b-list-group-item',[_vm._v("Monthly NHMIS Insights")])],1),_c('router-link',{attrs:{"to":"/dashboard/Disease_Surveillance","target":"_blank"}},[_c('b-list-group-item',[_vm._v("Disease Surveillance")])],1),_c('router-link',{attrs:{"to":"/external-ncdc","target":"_blank"}},[_c('b-list-group-item',[_vm._v("Disease Surveillance (NCDC)")])],1),_vm._l((_vm.publicDashboards.filter((dash) => {
                return (dash.category === 'health_outputs' && dash.isConfirmed)
              })),function(dashboard){return _c('a',{key:dashboard.id,attrs:{"target":"_blank","href":dashboard.link}},[_c('b-list-group-item',[_vm._v(" "+_vm._s(dashboard.name_of_dashboard)+" ")])],1)})],2)],1),_c('div',{staticClass:"col mb-3"},[_c('b-list-group',[_c('h5',{staticClass:"text-underline"},[_vm._v("Health Outcomes")]),_c('router-link',{attrs:{"to":"/dashboard/Health_Outcomes_and_Service_Coverage","target":"_blank"}},[_c('b-list-group-item',[_vm._v("Health Outcomes and Service Coverage")])],1),_vm._l((_vm.publicDashboards.filter((dash) => {
              return (dash.category === 'health_outcomes' && dash.isConfirmed)
            })),function(dashboard){return _c('a',{key:dashboard.id,attrs:{"target":"_blank","href":dashboard.link}},[_c('b-list-group-item',[_vm._v(" "+_vm._s(dashboard.name_of_dashboard)+" ")])],1)})],2)],1),_c('div',{staticClass:"col mb-3"},[_c('b-list-group',[_c('h5',{staticClass:"text-underline"},[_vm._v("Other Dashboards")]),_c('router-link',{attrs:{"to":"/custom","target":"_blank"}},[_c('b-list-group-item',[_vm._v(" Create Your Dashboard")])],1),_c('router-link',{attrs:{"to":"/health-profiles","target":"_blank"}},[_c('b-list-group-item',[_vm._v("Health Profiles")])],1),_c('a',{attrs:{"href":"https://ngf.fmohconnect.gov.ng/","target":"_blank"}},[_c('b-list-group-item',[_vm._v("Governors' Dashboard")])],1),_c('router-link',{attrs:{"to":"/data-source-triangulation","target":"_blank"}},[_c('b-list-group-item',[_vm._v("Data Source Triangulation Dashboard")])],1),_c('router-link',{attrs:{"to":"/advanced_analytics","target":"_blank"}},[_c('b-list-group-item',[_vm._v("Advanced Analytics")])],1)],1)],1),(_vm.isAuthenticated)?_c('div',{staticClass:"col mb-3"},[_c('b-list-group',[_c('h5',{staticClass:"text-underline"},[_vm._v("Custom Dashboards")]),_vm._l((_vm.userDashboards),function(dashboard){return _c('div',{key:dashboard.id},[_c('router-link',{attrs:{"to":'/dashboard/' + dashboard.name,"target":"_blank"}},[_c('b-list-group-item',[_vm._v(" "+_vm._s(dashboard.title))])],1)],1)})],2)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }